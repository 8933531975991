YUI.add('case-editor-app-analysis', function (Y) {
	"use strict";

	Y.CaseEditorAppAnalysis = Y.Base.create('CaseEditorAppAnalysis', Y.smst.CaseEditorAppBase,
			[Y.AppOverlays], {

        vueApp: null,

		setHeaderHelp : function() {

        	var headerHelp =
				 'Hier kannst Du die genauen Berechnungen noch einmal nachvollziehen.'

			Y.one('.case-editor-section-header-description__wrapper').append(
				Y.Node.create('<div class="case-editor-section-help">' + headerHelp + '</div>')
			);
		},

		// --- navigation handling

		// this is our new 'openArea'
		handleNavigation:function (navInfo) {
			var that = this;

			Y.log('handleNavigation', 'DEBUG', 'CaseEditorAppAnalysis');
			var template = Y.Node.create(
				Y.HandlebarsTemplates.lookup('case-editor-analysis-main')()
			);
			that.get('container').setHTML(template);

			that.setHeaderHelp();

			var sheets = Y.UserCase.getSpreadsheets();
			that.vueApp = onse.default.makeCaseAnalysisApp({
				gvErrors: Y.gvErrors,
				smartCheckIsOk: Y.smartCheckOk,
				sheets: sheets,
				previewProps: {
					mounted: function (element) {
						that.renderAnalysis('dialog', element);
					},
					show: function (content) {
						var widget = that.get('dialog')
						if (content.report) {
							var report = content.report;
							widget.selectReport(report.id, report.type, report.sessionId);
						}
						if (content.sheet) {
							widget.selectSheet(content.sheet);
						}
					}
				},
				overviewProps: {
					mounted: function (element) {
					  that.renderAnalysis('overview', element);
					},
					show: function (content) {
					  if (content.report) {
					      var report = content.report;
						  that.get('overview').selectReport(report.id, report.type, report.sessionId);
					  }
					}
				}
			});
			that.vueApp.app.$mount('#vue-case-analysis')

			if(Y.one("#case-editor-main-controls")) {
				Y.one("#case-editor-main-controls").setStyle('display', 'none');
			}
		},


		renderAnalysis:function (name, element) {
			var that = this,
				preview,
				template = Y.Node.create(
					Y.HandlebarsTemplates.lookup('case-editor-analysis-preview')({
						name: name
					})
			);

			// convert the dom element to a YUI HTMLElement
			Y.one(element).setHTML(template);
			// create the preview area
			preview = new Y.analysis.AnalysisPreview({
				contentBox:'#case-editor-analysis-report-'+name+'-body',
				controlBox:'#case-editor-analysis-report-'+name+'-header',
				boundingBox:'#case-editor-analysis-report-'+name,
				onse:that.onse

			});
			preview.render();
			that.set(name, preview);
		},

		destructor:function () {
			var instance = this;

			if (instance.vueApp) {
				instance.vueApp.app.$destroy();
				instance.vueApp.eventBus.$destroy();
			}

			if (null != instance.get('preview')) {
				instance.get('preview').destroy();
			}
			if (null != instance.get('overview')) {
				instance.get('overview').destroy();
			}
		},


	}, {
		ATTRS:{
			container:{
				valueFn:function () {
					return Y.Node.create('<div id="case-editor-analysis-details"/>');
				}
			},
			dialog:{
				value:null
			},
			overview:{
				value:null
			}

		}
	});

}, '1.0.0', {
	requires:[
		'app',
		'base-build',
		'case-editor-app-base',
		'handlebars-view',
		'io-base',
		'node',
		'smst-lang',
		'analysis-preview',
		'substitute',
        'node-event-simulate',
		'print-preview',
		'spreadsheets',
		'spreadsheet'
	]});
