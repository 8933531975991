// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-filing2-documents', function (Y) {
    'use strict'

    Y.CaseEditorAppFiling2DocumentsPrintView = Y.Base.create('CaseEditorAppFiling2DocumentsPrintView', Y.HandlebarsView, [], {
        template: 'case-editor-filing2-documents-print',
        param: 'printed',

        initializer: function () {
            this.get('container').delegate('click', this._handleClick, 'input', this)
        },

        _handleClick: function () {
            var cont = this.get('container')
            var tmpNode = cont.one('input')

            var checked = tmpNode.get('checked')
            var button = cont.one('#ui-go-to-filing2-send')
            var pathFilled = true

            if (!button) {
                if (!this.get('filingState').documentsPrinted) {
                    pathFilled = false
                }
            }

            if (checked && pathFilled) {
                var data = {}
                data[this.param] = checked
                Y.io('elster/saveInterviewSessionParam', {
                    method: 'POST',
                    data: data,
                })

                button.removeClass('btn-disabled')
            } else {
                button.addClass('btn-disabled')
            }
        },
    }, {
        ATTRS: {
            filingState: {},
        },
    })

    Y.CaseEditorAppFiling2DocumentsSendView = Y.Base.create('CaseEditorAppFiling2DocumentsSendView', Y.CaseEditorAppFiling2DocumentsPrintView, [], {
        // we extend the print view (above) and just use another template
        template: 'case-editor-filing2-documents-send',
        param: 'sent',
    })

    Y.CaseEditorAppFiling2Documents = Y.Base.create('CaseEditorAppFiling2Documents', Y.smst.CaseEditorAppBase, [Y.AppOverlays], {
        vueApp: null,
        views: {
            print: {type: Y.CaseEditorAppFiling2DocumentsPrintView},
            send: {type: Y.CaseEditorAppFiling2DocumentsSendView},
        },

        getLink: function (name) {
            return Y.smst.CaseEditor.SECTIONS.getByName(name).get('link')
        },

        events: {
            '#ui-go-to-filing2-print': {
                click: function (e) {
                    this.isDisabled(e) || this.fire('open', {link: this.getLink('filing2-process-documents/print')})
                },
            },
            '#ui-go-to-filing2-send': {
                click: function (e) {
                    var that = this

                    if (that.isDisabled(e)) {
                        return
                    }

                    //skip
                    Y.io('elster/saveInterviewSessionParam', {
                        method: 'POST',
                        context: this,
                        on: {
                            success: function () {
                                that.fire('open', {link: this.getLink('filing2-process-documents/send')})
                            },
                        },
                        data: {
                            printed: true,
                            sent: false,
                        },
                    })
                },
            },
        },

        isDisabled: function (e) {
            if (e.currentTarget.hasClass('btn-disabled')) {
                this.fire('alert', {msg: 'Bitte geh die Schritte nach der Reihenfolge durch und setze entsprechend Haken.'})
                return true
            } else {
                return false
            }
        },

        overlays: {},

        showView: function (view, config, options, callback) {
            this.constructor.superclass.showView.call(this, view, config, options, callback)
            var stateInfo = {
                elsterAvailable: config.filingState.elsterAvailable,
                notYetSentViaElster: config.filingState.notYetSentViaElster,
                elster2MailSendingNecessary: config.filingState.elsterIIMailSendingNecessary,
                documentsPrinted: config.filingState.documentsPrinted,
                documentsSent: config.filingState.documentsSent,
                receiptsList: config.receiptsList,
                mayUseElsterII: config.filingState.mayUseElsterII,
                willProvideElsterIIDocumentsPerMail: config.filingState.willProvideElsterIIDocumentsPerMail,
                elsterIIReceiptInfo: config.filingState.elsterIIReceiptInfo,
            }
            switch (view) {
                case 'print':
                    Y.one('#case-editor-main').removeClass('case-editor-main-body')
                    Y.one('#case-editor-main-header').setStyle('display', 'none')

                    this._loadPrintDocumentsApp(stateInfo,
                        this.get('config').tName,
                        this.get('config').t)
                    break
                case 'send':
                    Y.one('#case-editor-main').removeClass('case-editor-main-body')
                    Y.one('#case-editor-main-header').setStyle('display', 'none')

                    this._loadPostFilingDocumentsAndReceiptsApp(stateInfo)
                    break
                default:
                    if (this.vueApp && Y.one('#app')) {
                        Y.one('#case-editor-main').addClass('case-editor-main-body')
                        Y.one('#case-editor-main-header').setStyle('display', '')
                    }
                    break
            }
        },

        _loadPrintDocumentsApp(stateInfo, tokenName, csrfT) {
            var that = this
            var filingSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/send').get('link')

            let currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/print').get('link')

            const yuiSections = {
                previous: filingSectionLink,
                current: currentYuiSectionUrl,
                next: nextSectionLink,
            }

            that.vueApp = onse.default.makePrintFilingDocumentsApp({
                stateInfo: stateInfo,
                yuiSections: yuiSections,
                tokenName: tokenName,
                csrfToken: csrfT,
            })

            that.vueApp.app.$mount('#vue-print-app')
        },

        _loadPostFilingDocumentsAndReceiptsApp(stateInfo) {
            var that = this
            var nextView = 'final'
            var filingSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-final').get('link')
            let currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/send').get('link')

            const yuiSections = {
                previous: filingSectionLink,
                current: currentYuiSectionUrl,
                next: nextSectionLink,
            }
            that.vueApp = onse.default.makePostFilingDocumentsAndReceiptsApp({
                stateInfo: stateInfo,
                yuiSections: yuiSections,
                nextView: nextView,
            })

            that.vueApp.app.$mount('#vue-post-filing-docs-app')
        },

        handleNavigation: function (navInfo) {
            var formId = navInfo.formId
            var data = {
                filingState: this.get('filingState'),
                filingLink: this.getLink('filing2-process-filing'),
                sendLink: this.getLink('filing2-process-documents/send'),
                receiptsList: this.get('receiptsList'),
            }

            this.fire('removeWaiting')
            this.fire('waiting', {msg: 'Bitte warten...'})

            if (formId === 'print') {
                this.showView('print', data)
            } else {
                this.showView('send', data)
            }
            this.fire('removeWaiting')
        },
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-filing-documents"/>')
                },
            },
            receiptsList: {
                getter: function () {
                    var text = Y.UserCase.getValueFromCase('OF0011199', 'Standard', 1)
                    var receipts = []

                    if (text && text.value && text.value != '') {
                        receipts = text.value.trim().split('\n\n')
                    }

                    return receipts
                },
            },
            filingState: {},
        },
    })
}, '1.0.0', {
    requires: [
        'app',
        'base-build',
        'smart-handlebars-templates',
        'case-editor-app-base',
        'io-base',
        'json-parse',
        'node',
        'smst-lang',
    ],
})
